import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/digital/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import Img from "gatsby-image";
import SvgIconsSet from "../../../components/SvgIconsSet";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerBpa from "../../../images/services/digital/banner-bpa.png";
import BannerCRM from "../../../images/services/digital/banner-crm.jpg";
import BannerApi from "../../../images/services/digital/banner-api.png";
import BannerIot from "../../../images/services/digital/banner-iot.jpg";

//Process
import OurProcess from "../../../components/services/OurProcess";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";
import ConsultationBanner from "../../../images/consultation.jpg";

const DigitalTransformation = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="Digital Transformation and Automation"
        title="Digital Transformation"
        description="Enhance efficiency and improve productivity with business process automation, CRM/ERP professional services, API development & integration or IoT development. Get in touch for more information."
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Digital Transformation</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="Digital Transformation"
        subtitle="Unlock new business opportunities with transformational technological solutions"
        image={Banner}
      />
      {/* <ContrastSection
        title="Unlock new business opportunities with transformational technological solutions"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerBpa} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-1">Business Process Automation</h2>
            <p>
              <strong>Enhance efficiency and improve productivity.</strong>
              <br/>
              Simplify and speed up repetitive tasks to make operations more efficient and cost-effective. Reduce the risk of human error and produce auditable records with ease.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerCRM} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">CRM/ERP Professional Services</h2>
            <p>
              <strong>Streamline operations and enhance data-driven decision making.</strong>
              <br/>
              Manage customer relationships, internal processes and resources. Enhance extractable data insights to inform decision-making, increase efficiency and improve customer retention.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerApi} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">API Development & Integration</h2>
            <p>
              <strong>Create frictionless data exchange and functionality enhancements. </strong>
              <br/>
              We can create custom application interfaces to seamlessly connect systems and applications, delivering greater efficiency and data accuracy.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerIot} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">IoT &amp; Device Development</h2>
            <p>
              <strong>Enhance Business Intelligence & Operational efficiency. </strong>
              <br/>
              Our team are experts in the sophisticated engineering of interconnecting digital devices and systems via the internet to increase process efficiency and improve customer experience. Enable innovative solutions across various industries, services provided from electronics design to device management.
            </p>
          </div>
        </div>
        <div className="getting-touch"><GetInTouchbtn textdce={'Free consultation'} /></div>
      </div>
      <Container>
        <OurProcess />
      </Container>
      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default DigitalTransformation;
